import { gql, useMutation } from '@apollo/client'
import Papa from 'papaparse'
import {
  AdminCreateSalesforceAddressesDocument,
  Core_CreateSalesforceAddressResult,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { flockTheme, useSnackbar } from '@flock/shared-ui'
import { useEffect, useState } from 'react'
import {
  AddressCreationPageProps,
  AddressCreationPagePresentationalProps,
  FormResults,
  HomeDetails,
  AddressRowData,
  CsvFormResults,
} from './addressCreationPageTypes'

export const CREATE_SALESFORCE_ADDRESSES = gql`
  mutation AdminCreateSalesforceAddresses(
    $input: Core_CreateSalesforceAddressesRequestInput!
  ) {
    createSalesforceAddresses(input: $input) {
      results {
        formattedAddress
        addressId
        status
      }
    }
  }
`

const getPropertyConditionString = (conditionScore: number) => {
  switch (conditionScore) {
    case 1: {
      return '1 - requires complete renovation'
    }
    case 2: {
      return '2 - below average'
    }
    case 3: {
      return '3 - average'
    }
    case 4: {
      return '4 - great'
    }
    case 5: {
      return '5 - pristine'
    }
    default: {
      return ''
    }
  }
}

export const statusColorMap: { [key: string]: string } = {
  Success: flockTheme.palette.green1.main,
  'Warn: Verify address matches': flockTheme.palette.softGold.main,
  'Skipped: Already assigned to different lead/account':
    flockTheme.palette.softGold.main,
  Failed: flockTheme.palette.errorRed.main,
}

const parseIntOrUndefined = (value: string) => {
  const parsed = parseInt(value, 10)
  return Number.isNaN(parsed) ? undefined : parsed
}

const parseFloatOrUndefined = (value: string) => {
  const parsed = parseFloat(value)
  return Number.isNaN(parsed) ? undefined : parsed
}

const parseCsv = async (file: File) => {
  const content = await file.text()
  return new Promise<AddressRowData[]>((resolve, reject) => {
    Papa.parse(content, {
      complete: (result) => {
        try {
          const { data } = result
          // Create a map of the headers to the index
          const headerMap = (data[0] as any).reduce(
            (acc: any, header: string, index: number) => {
              acc[header] = index
              return acc
            },
            {}
          )

          const addressRowData = data.slice(1).map((row: any) => ({
            inputAddress: row[headerMap.Address],
            inputUnit: row[headerMap['Unit #']],
            inputCity: row[headerMap.City],
            inputState: row[headerMap.State],
            inputZip: row[headerMap.Zip],
            bedrooms: parseIntOrUndefined(row[headerMap.Bedrooms]),
            totalBathrooms: parseFloatOrUndefined(
              row[headerMap['Total Bathrooms']]
            ),
            buildingSqft: parseIntOrUndefined(row[headerMap.Sqft]),
            yearBuilt: parseIntOrUndefined(row[headerMap['Year Built']]),
            leaseAmount: parseIntOrUndefined(row[headerMap['Lease Amount']]),
            mortgageAmount: parseIntOrUndefined(
              row[headerMap['Mortgage Amount']]
            ),

            propertyCondition: parseIntOrUndefined(
              row[headerMap['Property Condition']]
            ),
            propertyConditionNotes: row[headerMap['Property Condition Notes']],
            annualHoa: row[headerMap['Annual HOA']],
            leaseType: row[headerMap['Lease Type']],
            leaseEnd: row[headerMap['Lease End']],
            propertyType: row[headerMap['Property Type']],
          }))
          resolve(addressRowData)
        } catch (e) {
          reject(e)
        }
      },
      error: (error: any) => {
        reject(error)
      },
    })
  })
}

const useAddressCreationPage: (
  props: AddressCreationPageProps
) => AddressCreationPagePresentationalProps = () => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<Core_CreateSalesforceAddressResult[]>(
    []
  )
  const [leadId, setLeadId] = useState('')
  const [accountId, setAccountId] = useState('')
  const [currentTab, setCurrentTab] = useState(0)
  const { notify } = useSnackbar()

  const [createSalesforceAddresses] = useMutation(
    AdminCreateSalesforceAddressesDocument
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const leadIdParam = urlParams.get('leadId')
    const accountIdParam = urlParams.get('accountId')
    setLeadId(leadIdParam as string)
    setAccountId(accountIdParam as string)
  }, [])

  const onSubmit = async (inputs: FormResults, reset?: () => void) => {
    setLoading(true)
    const addressesToCreate = inputs.addressInfo.map((address) => {
      const {
        addressData,
        mortgageAmount,
        propertyType,
        yearBuilt,
        propertyCondition,
        propertyConditionNotes,
        annualHoa,
      } = address
      const details: HomeDetails[] = []
      let numUnits = 1
      switch (propertyType) {
        case 'duplex':
          numUnits = 2
          break
        case 'triplex':
          numUnits = 3
          break
        case 'fourplex':
          numUnits = 4
          break
        default:
          break
      }

      for (let j = 0; j < numUnits; j += 1) {
        const originalDate = address[`leaseEnd${j + 1}`]
        let leaseEnd = ''
        if (originalDate) {
          leaseEnd = new Date(originalDate).toISOString().slice(0, 10)
        }

        const homeDetail = {
          unitName: address[`unitName${j + 1}`],
          beds: address[`beds${j + 1}`],
          baths: address[`baths${j + 1}`],
          sqft: address[`sqft${j + 1}`],
          propertyCondition: address[`propertyCondition${j + 1}`],
          leaseType: address[`leaseType${j + 1}`],
          leaseEnd,
          rentAmount: address[`rentAmount${j + 1}`],
        }
        details.push(homeDetail)
      }
      let { formattedAddress } = addressData
      if (numUnits === 1 && details[0].unitName !== '') {
        formattedAddress = `${addressData.streetNumber} ${addressData.streetAddress}, ${details[0].unitName}, ${addressData.city}, ${addressData.state} ${addressData.zipcode}`
      }
      return {
        leadId,
        accountId,
        formattedAddress,
        mortgageAmount: mortgageAmount || 0,
        propertyType,
        yearBuilt,
        propertyCondition,
        propertyConditionNotes,
        annualHoa,
        details,
      }
    })

    try {
      const { data } = await createSalesforceAddresses({
        variables: {
          input: {
            addresses: addressesToCreate,
          },
        },
      })

      if (data?.createSalesforceAddresses?.results) {
        setResults(
          data.createSalesforceAddresses
            .results as Core_CreateSalesforceAddressResult[]
        )
        if (reset) {
          reset()
        }
      }
    } catch (e) {
      notify(`An error occurred ${e.message}`, 'error')
    }
    setLoading(false)
  }

  const onSubmitCsv = async (
    csvResults: CsvFormResults,
    reset?: () => void
  ) => {
    setLoading(true)
    const addressRowData = await parseCsv(csvResults.csvFile[0])
    // For rows that have the same address, we want to group them together
    const groupedAddresses = addressRowData.reduce((acc, address) => {
      const key = `${address.inputAddress}`
      if (acc[key]) {
        acc[key].push(address)
      } else {
        acc[key] = [address]
      }
      return acc
    }, {} as { [key: string]: AddressRowData[] })

    // Create the parent address data and then create the unit data and attach it to the parent
    const addressesToCreate = Object.values(groupedAddresses).map(
      (addresses) => {
        const parentAddress = addresses[0]
        const details = addresses.map((address) => ({
          unitName: address.inputUnit,
          beds: address.bedrooms,
          baths: address.totalBathrooms,
          sqft: address.buildingSqft,
          rentAmount: address.leaseAmount,
          leaseType: address.leaseType,
          leaseEnd: address.leaseEnd
            ? new Date(address.leaseEnd).toISOString().slice(0, 10)
            : undefined,
        }))

        // If addresses length is not 1, remove any details that do not have a unit name
        if (addresses.length > 1) {
          details.forEach((detail) => {
            if (!detail.unitName) {
              const index = details.indexOf(detail)
              details.splice(index, 1)
            }
          })
        }

        let { propertyType } = parentAddress || 'singlefamily'
        if (addresses.length === 2) {
          propertyType = 'duplex'
        } else if (addresses.length === 3) {
          propertyType = 'triplex'
        } else if (addresses.length === 4) {
          propertyType = 'fourplex'
        } else if (addresses.length > 4) {
          propertyType = 'multifamily'
        }

        let formattedAddress = `${parentAddress.inputAddress}, ${parentAddress.inputCity}, ${parentAddress.inputState} ${parentAddress.inputZip}`
        if (addresses.length === 1 && parentAddress.inputUnit !== '') {
          formattedAddress = `${parentAddress.inputAddress}, ${parentAddress.inputUnit}, ${parentAddress.inputCity}, ${parentAddress.inputState} ${parentAddress.inputZip}`
        }
        return {
          leadId,
          accountId,
          formattedAddress,
          mortgageAmount: parentAddress.mortgageAmount || 0,
          propertyType,
          propertyCondition: getPropertyConditionString(
            parentAddress.propertyCondition as number
          ),
          propertyConditionNotes: parentAddress.propertyConditionNotes,
          annualHoa: parentAddress.annualHoa,
          yearBuilt: parentAddress.yearBuilt,
          details,
        }
      }
    )
    try {
      const { data } = await createSalesforceAddresses({
        variables: {
          input: {
            addresses: addressesToCreate,
          },
        },
      })

      if (data?.createSalesforceAddresses?.results) {
        setResults(
          data.createSalesforceAddresses
            .results as Core_CreateSalesforceAddressResult[]
        )
        if (reset) {
          reset()
        }
      }
    } catch (e) {
      notify(`An error occurred ${e.message}`, 'error')
    }
    setLoading(false)
  }

  return {
    onSubmit,
    loading,
    results,
    currentTab,
    setCurrentTab,
    onSubmitCsv,
  }
}

export default useAddressCreationPage
