import React from 'react'
import {
  flockSquareTheme,
  LibraryThemeProvider,
  FlockLogo,
  InfoIcon,
  TrackedLink,
  TrackedButton,
  Dropdown,
} from '@flock/shared-ui'

import {
  CssBaseline,
  Box,
  Typography,
  Tooltip,
  ThemeProvider,
  CircularProgress,
} from '@mui/material'
import useIndividualSowProjectPage from './useIndividualSowProjectPage'
import { IndividualSowProjectPageProps } from './individualSowProjectPageTypes'
import SolutionRowContainer from '../SolutionRowContainer'
import DeficiencyRow from './DeficiencyRow/DeficiencyRow'
import SowSaveContextProvider from './SowSaveContextProvider'
import SowSaveStateRenderer from './SowSaveStateRenderer'
import AssetRow from './AssetRow/AssetRow'
import SowDeficiencyModal from './SowDeficiencyModal/SowDeficiencyModal'
import SowAssetModal from './SowAssetModal/SowAssetModal'
import EditPropertyDataModal from './EditPropertyDataModal'
import ExportModal from './ExportModal/ExportModal'
import CreateSowSubProjectModule from '../CreateSowSubProjectModule'
import { SOW_HOME } from '../../../constants'

const localeStringOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

const Divider = () => (
  <Box
    width="2px"
    height="36px"
    sx={{
      backgroundColor: 'moneyGreen.main',
    }}
  />
)

const SummaryRow = (props: { label: string; value: string; color: string }) => {
  const { label, value, color } = props
  return (
    <Box
      display="flex"
      p="8px"
      width="100%"
      borderRadius="8px"
      sx={{
        backgroundColor: color || 'green0.main',
      }}
    >
      <Box display="flex" px="8px" width="100%" justifyContent="space-between">
        <Typography variant="c1m" fontWeight="600">
          {label}
        </Typography>
        <Typography variant="c1m" fontWeight="600">
          ${value}
        </Typography>
      </Box>
    </Box>
  )
}

const LabelledTopField = (props: {
  label: string
  children: React.ReactNode
  alignRight?: boolean
  tooltip?: React.ReactNode
}) => {
  const { label, children, alignRight, tooltip } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={alignRight ? 'flex-end' : 'flex-start'}
    >
      <Box display="flex" gap="4px">
        <Typography variant="p4">{label}</Typography>
        {tooltip && (
          <Tooltip
            title={tooltip}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'gray1.main',
                  maxWidth: '304px',
                  borderRadius: '9px',
                  boxShadow:
                    '0px 8px 32px 0px rgba(91, 115, 139, 0.24), 0px 0px 2px 0px rgba(91, 115, 139, 0.16)',
                  color: 'gray8.main',
                  fontFamily: 'Outfit',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px' /* 142.857% */,
                  '& .MuiTooltip-arrow': {
                    color: 'gray1.main',
                  },
                },
              },
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              height="12px"
              display="flex"
              alignItems="center"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="12px" color="#FFB029" />
            </Box>
          </Tooltip>
        )}
      </Box>
      {children}
    </Box>
  )
}

LabelledTopField.defaultProps = {
  alignRight: false,
  tooltip: undefined,
}

const IndividualSowProjectPage = (props: IndividualSowProjectPageProps) => {
  const presentationalProps = useIndividualSowProjectPage(props)
  const {
    formattedAddress,
    beds,
    baths,
    squareFootage,
    yearBuilt,
    editModalOpen,
    setEditModalOpen,

    inspectionDate,

    sowProjects,
    selectedProject,
    setSelectedProject,

    showAddUnit,
    setShowAddUnit,

    expandedAll,
    setExpandedAll,

    projectDeficiencies,
    openEditDeficiency,

    projectAssets,
    openEditAsset,

    costCatalog,
    deficiencyCatalog,
    locationOptions,
    categoryOptions,
    deficiencyOptions,

    unitHealthAndSafetyTotal,
    unitFirstTurnTotal,
    unitZeroToThreeYearsTotal,
    unitNonImmediatelyAddressableTotal,
    unitImmediatelyAddressableTotal,
    interiorTotal,
    exteriorTotal,
    overallTotal,

    showEditDeficiency,
    selectedDeficiency,
    onCloseEditDeficiency,

    showEditAsset,
    selectedAsset,
    onCloseEditAsset,

    exportModalOpen,
    setExportModalOpen,

    propertyHealthAndSafetyTotal,
    propertyFirstTurnTotal,
    propertyZeroToThreeYearsTotal,
    propertyImmediatelyAddressableTotal,
    propertyNonImmediatelyAddressableTotal,
    propertyTotal,

    loading,
    refetch,

    newUnitOptions,
    costbookUuid,
    parentProjectType,
    parentProjectId,

    sync,
    syncLoading,

    deficiencySortBy,
    setDeficiencySortBy,
  } = presentationalProps

  const deficiencySortOptions = [
    { value: 'location', label: 'Location' },
    { value: 'category', label: 'Category' },
    { value: 'deficiency', label: 'Deficiency' },
    { value: 'status', label: 'Status' },
  ]

  if (sowProjects.length === 0) {
    return (
      <ThemeProvider theme={flockSquareTheme}>
        <CssBaseline />
        <LibraryThemeProvider theme={flockSquareTheme}>
          <Box
            minHeight="100vh"
            px="60px"
            py="32px"
            sx={{
              backgroundColor: 'green0.main',
            }}
          >
            <Box display="flex" gap="32px" alignItems="center">
              <Box display="flex" alignItems="center" gap="16px">
                <FlockLogo />
                <Box width="1px" height="32px" bgcolor="rgba(0, 0, 0, 0.1)" />
                <Typography color="moneyGreen.main" variant="cta">
                  Scope of Work
                </Typography>
              </Box>
            </Box>

            <Box
              width="100%"
              height="50vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography>
                  Failed to load project. Please refresh or contact engineering.
                </Typography>
              )}
            </Box>
          </Box>
        </LibraryThemeProvider>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={flockSquareTheme}>
      <CssBaseline />
      <LibraryThemeProvider theme={flockSquareTheme}>
        <SowSaveContextProvider loadingData={loading} refetchProject={refetch}>
          <Box
            minHeight="100vh"
            px="60px"
            py="32px"
            sx={{
              backgroundColor: 'green0.main',
            }}
          >
            <Box display="flex" flexDirection="column" gap="24px">
              {/* Header */}
              <Box
                display="flex"
                gap="32px"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.location.href = SOW_HOME
                }}
              >
                <Box display="flex" alignItems="center" gap="16px">
                  <FlockLogo />
                  <Box width="1px" height="32px" bgcolor="rgba(0, 0, 0, 0.1)" />
                  <Typography color="moneyGreen.main" variant="cta">
                    Scope of Work
                  </Typography>
                </Box>
                <SowSaveStateRenderer />
              </Box>

              {/* Property DNA */}
              <Box display="flex" flexDirection="column" gap="12px">
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" gap="8px">
                    <Typography variant="ctam">{formattedAddress}</Typography>
                    <TrackedLink
                      variant="ctam"
                      onClick={() => {
                        setEditModalOpen(true)
                      }}
                    >
                      edit
                    </TrackedLink>
                  </Box>
                  <Box display="flex" gap="8px">
                    {parentProjectType === 'inside_maps' && (
                      <TrackedButton
                        variant="secondary"
                        size="micro"
                        disabled={syncLoading}
                        onClick={sync}
                      >
                        {syncLoading ? 'Syncing...' : 'Sync'}
                      </TrackedButton>
                    )}

                    <TrackedButton
                      variant="secondary"
                      size="micro"
                      onClick={() => {
                        setExportModalOpen(true)
                      }}
                    >
                      Export
                    </TrackedButton>

                    {parentProjectType === 'inside_maps' && (
                      <TrackedButton
                        variant="secondary"
                        size="micro"
                        onClick={() => {
                          window.open(
                            `https://www.insidemaps.com/project/${parentProjectId}`,
                            '_blank'
                          )
                        }}
                      >
                        InsideMaps
                      </TrackedButton>
                    )}
                  </Box>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" gap="24px">
                    <LabelledTopField
                      label="Beds"
                      tooltip={
                        sowProjects.length > 0 &&
                        sowProjects[0].salesforceBeds !== beds &&
                        `Salesforce: ${sowProjects[0].salesforceBeds}`
                      }
                    >
                      <Typography variant="c1">{beds}</Typography>
                    </LabelledTopField>
                    <LabelledTopField
                      label="Baths"
                      tooltip={
                        sowProjects.length > 0 &&
                        sowProjects[0].salesforceBaths !== baths &&
                        `Salesforce: ${sowProjects[0].salesforceBaths}`
                      }
                    >
                      <Typography variant="c1">{baths}</Typography>
                    </LabelledTopField>
                    <LabelledTopField
                      label="Sqft"
                      tooltip={
                        sowProjects.length > 0 &&
                        sowProjects[0].salesforceSquareFootage !==
                          squareFootage &&
                        `Salesforce: ${sowProjects[0].salesforceSquareFootage}`
                      }
                    >
                      <Typography variant="c1">
                        {squareFootage.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      </Typography>
                    </LabelledTopField>
                    <LabelledTopField label="Year Built">
                      <Typography variant="c1">{yearBuilt}</Typography>
                    </LabelledTopField>
                    {sowProjects.map((project, idx) => {
                      if (idx === 0 || sowProjects.length !== 1) return <></>
                      const { projectName, isOccupied } = project
                      return (
                        <LabelledTopField
                          label={`${projectName} Occupied`}
                          tooltip={
                            sowProjects.length > 0 &&
                            sowProjects[0].salesforceHasTenant !== isOccupied &&
                            `Salesforce: ${
                              sowProjects[0].salesforceHasTenant ? 'Yes' : 'No'
                            }`
                          }
                        >
                          <Typography variant="c1">
                            {isOccupied ? 'Yes' : 'No'}
                          </Typography>
                        </LabelledTopField>
                      )
                    })}
                  </Box>

                  <Box
                    display="flex"
                    gap="24px"
                    justifyContent="flex-end"
                    sx={{
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box display="flex" justifyContent="flex-end" gap="24px">
                      <LabelledTopField label="Inspection Date" alignRight>
                        <Typography variant="c1">{inspectionDate}</Typography>
                      </LabelledTopField>

                      <Divider />

                      {/* Urgency Totals */}
                      <LabelledTopField label="Health & Safety" alignRight>
                        <Typography variant="c1">
                          $
                          {propertyHealthAndSafetyTotal.toLocaleString(
                            'en-US',
                            localeStringOptions
                          )}
                        </Typography>
                      </LabelledTopField>
                      <LabelledTopField label="First Turn" alignRight>
                        <Typography variant="c1">
                          $
                          {propertyFirstTurnTotal.toLocaleString(
                            'en-US',
                            localeStringOptions
                          )}
                        </Typography>
                      </LabelledTopField>
                      <LabelledTopField label="0-3 Yr" alignRight>
                        <Typography variant="c1">
                          $
                          {propertyZeroToThreeYearsTotal.toLocaleString(
                            'en-US',
                            localeStringOptions
                          )}
                        </Typography>
                      </LabelledTopField>
                      <Divider />
                    </Box>

                    <Box display="flex" gap="24px">
                      <LabelledTopField label="Imm. Address." alignRight>
                        <Typography variant="c1">
                          $
                          {propertyImmediatelyAddressableTotal.toLocaleString(
                            'en-US',
                            localeStringOptions
                          )}
                        </Typography>
                      </LabelledTopField>
                      <LabelledTopField label="Non-Imm. Address." alignRight>
                        <Typography variant="c1">
                          $
                          {propertyNonImmediatelyAddressableTotal.toLocaleString(
                            'en-US',
                            localeStringOptions
                          )}
                        </Typography>
                      </LabelledTopField>
                      <LabelledTopField label="Total" alignRight>
                        <Typography variant="c1">
                          $
                          {propertyTotal.toLocaleString(
                            'en-US',
                            localeStringOptions
                          )}
                        </Typography>
                      </LabelledTopField>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" gap="24px">
                    <LabelledTopField label="Notes">
                      <Typography variant="c1">
                        {sowProjects[0].notes}
                      </Typography>
                    </LabelledTopField>
                  </Box>
                </Box>
              </Box>

              {/* Unit Selector */}
              <Box display="flex" gap="16px">
                {sowProjects.map((project, idx) => {
                  const { uuid, projectName } = project
                  return (
                    <Box
                      key={uuid}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="16px"
                      p="12px 16px"
                      width="100%"
                      sx={{
                        transition:
                          sowProjects.length > 1
                            ? 'background-color 0.3s ease'
                            : '',
                        backgroundColor:
                          selectedProject === idx && !showAddUnit
                            ? 'green2.main'
                            : 'white.main',
                        cursor: 'pointer',

                        '&:hover': {
                          backgroundColor:
                            sowProjects.length > 1 ? 'green1.main' : '',
                        },
                      }}
                      onClick={() => {
                        setShowAddUnit(false)
                        setSelectedProject(idx)
                      }}
                    >
                      <Typography variant="c1">{projectName}</Typography>
                    </Box>
                  )
                })}
                {newUnitOptions.length > 0 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="16px"
                    p="12px 16px"
                    width="100%"
                    sx={{
                      transition: 'background-color 0.3s ease',
                      backgroundColor: showAddUnit
                        ? 'green2.main'
                        : 'white.main',
                      cursor: 'pointer',

                      '&:hover': {
                        backgroundColor: 'green1.main',
                      },
                    }}
                    onClick={() => {
                      setShowAddUnit(!showAddUnit)
                    }}
                  >
                    <Typography variant="c1">Add Unit</Typography>
                  </Box>
                )}
              </Box>

              {/* Add Unit */}
              {showAddUnit && (
                <CreateSowSubProjectModule
                  parentProjectUuid={
                    sowProjects.length ? sowProjects[0].uuid : ''
                  }
                  costbookUuid={costbookUuid}
                  parentProjectType={parentProjectType}
                  inspectionDate={
                    sowProjects.length
                      ? sowProjects[0].inspectionDate
                      : undefined
                  }
                  unitOptions={newUnitOptions}
                  refetch={refetch}
                />
              )}

              {/* SOW Items */}
              {!showAddUnit && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="8px"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    p="16px"
                    sx={{
                      borderRadius: '16px',
                      backgroundColor: 'white.main',
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" gap="16px">
                        <Box
                          display="flex"
                          alignItems="center"
                          px="12px"
                          sx={{
                            borderRadius: '8px',
                            backgroundColor: 'green0.main',
                          }}
                        >
                          {sowProjects.length > 0 && (
                            <Typography variant="c1m">
                              {sowProjects?.[selectedProject].beds}
                              {' bed | '}
                              {sowProjects?.[selectedProject].baths}
                              {' bath | '}
                              {sowProjects?.[
                                selectedProject
                              ].squareFootage.toLocaleString(
                                'en-US',
                                localeStringOptions
                              )}
                              {' sqft '}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="8px"
                          alignItems="center"
                        >
                          <Box
                            width="60px"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Typography variant="c1m">Sort By</Typography>
                          </Box>

                          <Dropdown
                            variant="outlined"
                            size="small"
                            InputProps={{
                              style: {
                                height: '28px',
                                width: '150px',
                                padding: '0px',
                                borderRadius: '16px',
                              },
                            }}
                            options={deficiencySortOptions}
                            value={deficiencySortBy}
                            onChange={(e) =>
                              setDeficiencySortBy(e.target.value)
                            }
                          />
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        gap="4px"
                        p="8px"
                      >
                        <TrackedLink
                          variant="c1m"
                          onClick={() => setExpandedAll(true)}
                        >
                          EXPAND ALL
                        </TrackedLink>
                        <Typography variant="c1m">/</Typography>
                        <TrackedLink
                          variant="c1m"
                          onClick={() => setExpandedAll(false)}
                          sx={{
                            color: 'errorRed.main',

                            '&:hover': {
                              color: 'exclamationRed.main',
                            },
                          }}
                        >
                          COLLAPSE ALL
                        </TrackedLink>
                      </Box>
                    </Box>

                    <Box display="flex" px="4px">
                      <SolutionRowContainer>
                        <Box display="flex">
                          <Typography variant="c1m" noWrap>
                            Location
                          </Typography>
                          <Typography variant="p4" noWrap>
                            {' '}
                            / Category
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Typography variant="c1m" noWrap>
                            Category | Deficiency
                          </Typography>
                          <Typography variant="p4" noWrap>
                            {' '}
                            / Solution
                          </Typography>
                        </Box>
                        <Typography variant="c1m">Description</Typography>
                        <Typography variant="c1m">UOM</Typography>
                        <Typography variant="c1m" color="gray5.main" noWrap>
                          Unit Pr.
                        </Typography>
                        <Typography variant="c1m">Qty</Typography>
                        <Typography variant="c1m">Urgency</Typography>
                        <Typography variant="c1m">Adj.</Typography>
                        <Typography variant="c1m" noWrap>
                          Total Cost
                        </Typography>
                      </SolutionRowContainer>
                    </Box>
                    <Box
                      pl="16px"
                      pt="8px"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography variant="c1m">Deficiencies</Typography>
                      <TrackedLink
                        variant="c1m"
                        onClick={() => openEditDeficiency('')}
                      >
                        New
                      </TrackedLink>
                    </Box>
                    {projectDeficiencies.map((item) => {
                      const { uuid } = item
                      return (
                        <DeficiencyRow
                          key={uuid}
                          {...item}
                          expandedAll={expandedAll}
                          openEditDeficiency={openEditDeficiency}
                          costCatalog={costCatalog}
                          deficiencyCatalog={deficiencyCatalog}
                        />
                      )
                    })}
                    <Box
                      pl="16px"
                      pt="8px"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography variant="c1m">Assets</Typography>
                      <TrackedLink
                        variant="c1m"
                        onClick={() => openEditAsset('')}
                      >
                        New
                      </TrackedLink>
                    </Box>
                    {projectAssets.map((item) => {
                      const { uuid } = item
                      return (
                        <AssetRow
                          key={uuid}
                          {...item}
                          openEditAsset={openEditAsset}
                        />
                      )
                    })}

                    {/* Totals */}
                    {interiorTotal ? (
                      <SummaryRow
                        color="green0.main"
                        label="Interior Allocation"
                        value={interiorTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      />
                    ) : null}
                    {exteriorTotal ? (
                      <SummaryRow
                        color="green0.main"
                        label="Exterior Allocation"
                        value={exteriorTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      />
                    ) : null}
                    {sowProjects.length > 0 &&
                    sowProjects?.[selectedProject].projectName ? (
                      <SummaryRow
                        color="green1.main"
                        label="Total"
                        value={overallTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      />
                    ) : null}
                  </Box>
                </Box>
              )}

              {/* Unit Totals */}
              {selectedProject >= 0 && (
                <Box
                  display="flex"
                  gap="24px"
                  justifyContent="flex-end"
                  sx={{
                    flexWrap: 'wrap',
                  }}
                >
                  <Box display="flex" justifyContent="flex-end" gap="24px">
                    {/* Urgency Totals */}
                    <LabelledTopField label="Health & Safety" alignRight>
                      <Typography variant="c1">
                        $
                        {unitHealthAndSafetyTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      </Typography>
                    </LabelledTopField>
                    <LabelledTopField label="First Turn" alignRight>
                      <Typography variant="c1">
                        $
                        {unitFirstTurnTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      </Typography>
                    </LabelledTopField>
                    <LabelledTopField label="0-3 Yr" alignRight>
                      <Typography variant="c1">
                        $
                        {unitZeroToThreeYearsTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      </Typography>
                    </LabelledTopField>
                    <Divider />
                  </Box>

                  <Box display="flex" gap="24px">
                    <LabelledTopField label="Imm. Address." alignRight>
                      <Typography variant="c1">
                        $
                        {unitImmediatelyAddressableTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      </Typography>
                    </LabelledTopField>
                    <LabelledTopField label="Non-Imm. Address." alignRight>
                      <Typography variant="c1">
                        $
                        {unitNonImmediatelyAddressableTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      </Typography>
                    </LabelledTopField>
                    <LabelledTopField label="Total" alignRight>
                      <Typography variant="c1">
                        $
                        {overallTotal.toLocaleString(
                          'en-US',
                          localeStringOptions
                        )}
                      </Typography>
                    </LabelledTopField>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {showEditDeficiency && (
            <SowDeficiencyModal
              open={showEditDeficiency}
              onClose={onCloseEditDeficiency}
              costCatalog={costCatalog}
              deficiencyCatalog={deficiencyCatalog}
              deficiencyOptions={deficiencyOptions}
              locationOptions={locationOptions}
              categoryOptions={categoryOptions}
              keywords={selectedDeficiency?.keywords || []}
              deficiency={selectedDeficiency}
              projectUUID={sowProjects[selectedProject].uuid}
            />
          )}
          {showEditAsset && (
            <SowAssetModal
              open={showEditAsset}
              onClose={onCloseEditAsset}
              locationOptions={locationOptions}
              categoryOptions={categoryOptions}
              asset={selectedAsset}
              projectUUID={sowProjects[selectedProject].uuid}
            />
          )}
          {editModalOpen && (
            <EditPropertyDataModal
              open={editModalOpen}
              onClose={() => setEditModalOpen(false)}
              sowProjects={sowProjects}
            />
          )}
          {exportModalOpen && (
            <ExportModal
              open={exportModalOpen}
              onClose={() => setExportModalOpen(false)}
              sowProjects={sowProjects}
              formattedAddress={formattedAddress}
              beds={beds}
              baths={baths}
              inspectionDate={inspectionDate}
              squareFootage={squareFootage}
              yearBuilt={yearBuilt}
              immediateAddressableTotal={propertyImmediatelyAddressableTotal}
              nonImmediateAddressableTotal={
                propertyNonImmediatelyAddressableTotal
              }
              total={propertyTotal}
            />
          )}
        </SowSaveContextProvider>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default IndividualSowProjectPage
