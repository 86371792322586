import { useCallback, useEffect } from 'react'
import millify from 'millify'
import {
  CashFlowPageProps,
  CashFlowPagePresentationalProps,
} from './cashFlowPageTypes'
import { CheckboxLabel } from '../../printCollateralPageTypes'

const APPRECIATION_LOWER_BOUND = 0
const APPRECIATION_HIGHER_BOUND = 0.2
const APPRECIATION_HIGH_ASSUMPTION = 0.12

const getCashFlowRange = (netYield: number) => {
  if (netYield < 0.06) {
    return Math.round(netYield * 10000) / 100
  }
  return 6
}

const millifyWithPrecision = (amt: number, precision: number) => {
  if (amt < 1000) {
    return `$${Math.round(amt).toLocaleString()}`
  }

  const isNegative = amt < 0
  const absAmt = Math.abs(amt)

  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: precision,
  })

  return `${isNegative ? '-' : ''}$${formatter.format(absAmt)}`
}

type FlexibleCashFlowInputs = {
  startingEquity: number
  netYield: number
  cashFlowPercentage: number
  holdingPeriod: number
  hideNetYield: boolean
  flockAppreciation: number
}

type FlexibleCashFlowOutputs = {
  initialAccountValue: number[]
  cashWithdrawn: number[]
  cashDeduction: number[]
  totalReturn: number[]
  finalAccountValue: number[]
}

const DEDUCTION_FEE = 0.1

// https://docs.google.com/document/d/1MQlObku_f852fFX8p2yY9_zr7wUdMd-N1mJrP48ba80/edit?pli=1#heading=h.pqomula5iejf
export const calculateFlexibleCashFlowModel = (
  inputs: FlexibleCashFlowInputs
) => {
  const {
    startingEquity,
    netYield,
    cashFlowPercentage,
    holdingPeriod,
    hideNetYield,
    flockAppreciation,
  } = inputs

  const flexibleCashFlowOutputs: FlexibleCashFlowOutputs = {
    initialAccountValue: [],
    cashWithdrawn: [],
    cashDeduction: [],
    totalReturn: [],
    finalAccountValue: [],
  }

  let maxAllowance = hideNetYield
    ? 0
    : Math.round((netYield - 0.02) * 10000) / 10000
  maxAllowance = Math.min(maxAllowance, 0.04)

  // Flock appreciation --  year 1
  flexibleCashFlowOutputs.initialAccountValue = [startingEquity]
  flexibleCashFlowOutputs.totalReturn = [startingEquity * flockAppreciation]
  flexibleCashFlowOutputs.cashWithdrawn = [
    flexibleCashFlowOutputs.initialAccountValue[0] * cashFlowPercentage,
  ]

  if (cashFlowPercentage > maxAllowance) {
    const percentAdditionalCashflow =
      (cashFlowPercentage - maxAllowance) / cashFlowPercentage
    const additionalCashWithdrawn =
      percentAdditionalCashflow * flexibleCashFlowOutputs.cashWithdrawn[0]
    const cashDeduction = additionalCashWithdrawn * DEDUCTION_FEE
    flexibleCashFlowOutputs.cashDeduction = [cashDeduction]
  } else {
    flexibleCashFlowOutputs.cashDeduction = [0]
  }

  flexibleCashFlowOutputs.finalAccountValue = [
    flexibleCashFlowOutputs.initialAccountValue[0] +
      flexibleCashFlowOutputs.totalReturn[0] -
      flexibleCashFlowOutputs.cashWithdrawn[0] -
      flexibleCashFlowOutputs.cashDeduction[0],
  ]

  // Flock appreciation --  years 2 - 8
  for (let i = 1; i < 8; i += 1) {
    const initialAccountValue = flexibleCashFlowOutputs.finalAccountValue[i - 1]
    const totalReturn = initialAccountValue * flockAppreciation
    const cashWithdrawn = initialAccountValue * cashFlowPercentage

    flexibleCashFlowOutputs.initialAccountValue.push(initialAccountValue)
    flexibleCashFlowOutputs.totalReturn.push(totalReturn)
    flexibleCashFlowOutputs.cashWithdrawn.push(cashWithdrawn)

    // Deduct 10% of the cash withdrawn if the cash flow percentage is greater than the max allowance
    let cashDeduction = 0
    if (i < holdingPeriod && cashFlowPercentage > maxAllowance) {
      cashDeduction =
        (flexibleCashFlowOutputs.cashWithdrawn[i] *
          DEDUCTION_FEE *
          (cashFlowPercentage - maxAllowance)) /
        cashFlowPercentage
    }

    flexibleCashFlowOutputs.cashDeduction.push(cashDeduction)

    flexibleCashFlowOutputs.finalAccountValue.push(
      initialAccountValue + totalReturn - cashWithdrawn - cashDeduction
    )
  }

  return flexibleCashFlowOutputs
}

const useCashFlowPage: (
  props: CashFlowPageProps
) => CashFlowPagePresentationalProps = (props: CashFlowPageProps) => {
  const {
    startingAccountValue: startingInvestment,
    minimumHoldPeriod,
    uwCashOnCashYield: netYield,
    cashflowPageType,
    updateRenderState,
  } = props

  useEffect(() => {
    updateRenderState({
      section: cashflowPageType,
      doneRendering: true,
      numPages: 1,
    })
  }, [])

  let cashFlowPercentage = 0
  switch (cashflowPageType) {
    case CheckboxLabel.TOP_UP_CASH_FLOW:
      cashFlowPercentage = netYield
      break
    case CheckboxLabel.ALLOTMENT_CASH_FLOW:
      cashFlowPercentage = netYield - 0.02
      break
    case CheckboxLabel.REINVESTED_CASH_FLOW:
      cashFlowPercentage = 0
      break
    default:
      break
  }

  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() + 1)
  const currentYear = currentDate.getFullYear()

  // Hard set for the printout
  const customPercent = 10
  const selectedAppreciation = 0.1

  const hideNetYield = netYield === 0 || netYield === null

  const tickFormatterY = useCallback((val: any) => {
    const precision = val / 100 < 100000 || val / 100 > 1000000 ? 1 : 0 // if val is between 100k and 1m, show no decimal places since we will overflow on the chart
    return `$${millify(val, {
      precision,
      lowercase: true,
      units: ['', 'K', 'M', 'B', 'T'],
    })}`
  }, [])
  const cashFlowRange = getCashFlowRange(netYield)
  // TODO: Update shared flexible cash flow model
  const flexibleCashFlowOutputs = calculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield,
    cashFlowPercentage,
    holdingPeriod: minimumHoldPeriod || 7,
    hideNetYield,
    flockAppreciation: selectedAppreciation,
  })

  const noCashFlowOutputs = calculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield,
    cashFlowPercentage: 0,
    holdingPeriod: minimumHoldPeriod || 7,
    hideNetYield,
    flockAppreciation: hideNetYield
      ? APPRECIATION_HIGH_ASSUMPTION
      : APPRECIATION_HIGHER_BOUND,
  })

  const noAppreciationCashFlowOutputs = calculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield,
    cashFlowPercentage: 0.01 * cashFlowRange,
    holdingPeriod: minimumHoldPeriod || 7,
    hideNetYield,
    flockAppreciation: APPRECIATION_LOWER_BOUND,
  })

  const graphDomain = [
    noAppreciationCashFlowOutputs.finalAccountValue[minimumHoldPeriod || 7],
    noCashFlowOutputs.initialAccountValue[minimumHoldPeriod || 7],
  ]

  let data = [
    {
      name: `${currentYear}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[0],
    },
    {
      name: `${currentYear + 1}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[1],
    },
    {
      name: `${currentYear + 2}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[2],
    },
    {
      name: `${currentYear + 3}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[3],
    },
    {
      name: `${currentYear + 4}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[4],
    },
    {
      name: `${currentYear + 5}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[5],
    },
    {
      name: `${currentYear + 6}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[6],
    },
    {
      name: `${currentYear + 7}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValue[7],
    },
  ]

  // Want the graph to show at least 5 years, with a max of 7 years
  const graphYearPeriod = Math.max(5, Math.min(7, minimumHoldPeriod || 7))
  data = data.slice(0, graphYearPeriod + 1)

  return {
    ...props,
    hideNetYield,
    flexibleCashFlowOutputs,
    cashFlowSlider: hideNetYield ? 0 : cashFlowRange - 2,
    graphDomain,
    tickFormatterY,
    data,
    millifyWithPrecision,
    cashFlowRange,
    customPercent,
    cashFlowPercentage,
  }
}

export default useCashFlowPage
