import { useMutation } from '@apollo/client'
import { AdminUpdateScopeOfWorkItemDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { useEffect, useMemo, useState } from 'react'
import { useSowSaveContext } from '../SowSaveContextProvider'
import {
  DeficiencyRowProps,
  DeficiencyRowPresentationalProps,
} from './deficiencyRowTypes'

const useDeficiencyRow: (
  props: DeficiencyRowProps
) => DeficiencyRowPresentationalProps = (props: DeficiencyRowProps) => {
  const { uuid, expandedAll, deficiencyCatalog, deficiency } = props
  const [expanded, setExpanded] = useState(false)
  const { notify } = useSnackbar()
  const { onSaveSuccess, onSaveError, setSaveLoading, refetchProject } =
    useSowSaveContext()

  const [updateSOWItem] = useMutation(AdminUpdateScopeOfWorkItemDocument)
  const updateDeficiency = async (status: string) => {
    setSaveLoading(true)

    try {
      await updateSOWItem({
        variables: {
          input: {
            uuid,
            itemStatus: status,
          },
        },
      })
      onSaveSuccess()
      await refetchProject()
    } catch (e) {
      notify('Failed to update deficiency', 'error')
      onSaveError(`Failed to update deficiency: ${e.message}`)
    }
  }

  const solutionOptions = useMemo(() => {
    if (!deficiency || !deficiencyCatalog[deficiency]) return []
    return deficiencyCatalog[deficiency].map((item) => item.solution)
  }, [deficiency, deficiencyCatalog])

  useEffect(() => {
    setExpanded(expandedAll)
  }, [expandedAll])

  return {
    ...props,
    expanded,
    setExpanded,
    updateDeficiency,
    solutionOptions,
  }
}

export default useDeficiencyRow
