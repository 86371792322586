import {
  Core_SalesforceScenario,
  Core_ValuationType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatIntegerDollars } from '@flock/utils'
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FilledBox, OutlinedBox } from '../../SharedPrintCollateralComponents'
import { calculateFlexibleCashFlowModel } from '../CashFlowPage/useCashFlowPage'
import PortfolioRow from './PortfolioRow'

const VALUATION_DISCLAIMER = `Valuation assumes the midpoint of the range.`
const MORTGAGE_DISCLAIMER = `Flock will pay off any existing mortgage at closing.`
const TITLE_AND_CLOSING_DISCLAIMER = `These costs may vary and will be finalized by the title company.`
const RENT_REDUCTION_DISCLAIMER = `Because your property’s existing lease is below fair market rent, Flock adjusts for the income lost through the remainder of its term.`
const DAYS_IN_REMODEL_DISCLAIMER = `Because your property will undergo a remodeling process, Flock adjusts for the income lost during this time.`
const CASH_TAKEOUT_DISCLAIMER = `Any cash that you receive at close will be treated as a traditional taxable amount of income.`

type PortfolioSummaryProps = {
  equityAmount: number
  scenario: Core_SalesforceScenario
}

const PortfolioSummary = (props: PortfolioSummaryProps) => {
  const [offerPriceDollars, setOfferPriceDollars] = useState('')
  const [mortgageDollars, setMortgageDollars] = useState('')
  const [cashTakeoutDollars, setCashTakeoutDollars] = useState('')
  const [submarketRentDeductionDollars, setSubmarketRentDeductionDollars] =
    useState('')
  const [daysInRenoDeductionDollars, setDaysInRenoDeductionDollars] =
    useState('')
  const [brokerFeeDollars, setBrokerFeeDollars] = useState('')
  const [onboardingFeeDollars, setOnboardingFeeDollars] = useState('')
  const [closingCostsDollars, setClosingCostsDollars] = useState('')

  const [startingAccountValue, setStartingAccountValue] = useState('')

  const [cashFlowDollars, setCashFlowDollars] = useState('')
  const [topUpDollars, setTopUpDollars] = useState('')
  const [disclaimers, setDisclaimers] = useState<string[]>([])

  const { equityAmount, scenario } = props

  const {
    offerPrice,
    mortgage,
    cashTakeout,
    submarketRentDeduction,
    onboardingFee,
    closingCosts,
    daysInRenoDeduction,
    brokerFee,

    portfolioFcfPercent,
    minimumHoldPeriod,

    valuationType,
  } = scenario

  const isFinalValuation =
    valuationType === Core_ValuationType.ValuationTypeFinal

  useEffect(() => {
    let newDisclaimers: string[] = []

    setOfferPriceDollars(formatIntegerDollars(offerPrice!))
    setOnboardingFeeDollars(
      `-${formatIntegerDollars(onboardingFee! * 0.01 * offerPrice!)}`
    )
    if (!isFinalValuation) {
      newDisclaimers = [...newDisclaimers, VALUATION_DISCLAIMER]
    }
    if (mortgage) {
      setMortgageDollars(`-${formatIntegerDollars(mortgage)}`)
      newDisclaimers = [...newDisclaimers, MORTGAGE_DISCLAIMER]
    }

    setClosingCostsDollars(
      `-${formatIntegerDollars(Math.round(closingCosts! * offerPrice!))}`
    )
    newDisclaimers = [...newDisclaimers, TITLE_AND_CLOSING_DISCLAIMER]

    setCashTakeoutDollars(formatIntegerDollars(cashTakeout!))
    if (brokerFee) {
      setBrokerFeeDollars(
        `-${formatIntegerDollars(Math.round(offerPrice! * brokerFee * 0.01))}`
      )
    }
    if (submarketRentDeduction) {
      setSubmarketRentDeductionDollars(
        `-${formatIntegerDollars(submarketRentDeduction)}`
      )
      newDisclaimers = [...newDisclaimers, RENT_REDUCTION_DISCLAIMER]
    }

    if (daysInRenoDeduction) {
      setDaysInRenoDeductionDollars(
        `-${formatIntegerDollars(Math.round(daysInRenoDeduction))}`
      )
      newDisclaimers = [...newDisclaimers, DAYS_IN_REMODEL_DISCLAIMER]
    }

    if (cashTakeout) {
      if (cashTakeout < 0) {
        setCashTakeoutDollars(`+${formatIntegerDollars(cashTakeout * -1)}`)
      } else if (cashTakeout > 0) {
        setCashTakeoutDollars(`-${formatIntegerDollars(cashTakeout)}`)
        newDisclaimers = [...newDisclaimers, CASH_TAKEOUT_DISCLAIMER]
      }
    }

    const newStartingAccountValue =
      equityAmount < 0
        ? `-$${Math.round(equityAmount * -1).toLocaleString()}`
        : `$${Math.round(equityAmount).toLocaleString()}`

    setStartingAccountValue(newStartingAccountValue)

    const netYield = portfolioFcfPercent! * 0.01

    const selectedAppreciation = 0.1
    const topUpFlexibleCashFlowOutputs = calculateFlexibleCashFlowModel({
      startingEquity: equityAmount,
      netYield,
      cashFlowPercentage: netYield,
      holdingPeriod: minimumHoldPeriod || 7,
      hideNetYield: netYield === 0 || netYield === null,
      flockAppreciation: selectedAppreciation,
    })

    const allotmentFlexibleCashFlowOutputs = calculateFlexibleCashFlowModel({
      startingEquity: equityAmount,
      netYield: netYield - 0.02,
      cashFlowPercentage: netYield - 0.02,
      holdingPeriod: minimumHoldPeriod || 7,
      hideNetYield: netYield === 0 || netYield === null,
      flockAppreciation: selectedAppreciation,
    })

    const cashFlow = allotmentFlexibleCashFlowOutputs.cashWithdrawn[0]
    setCashFlowDollars(
      `$${cashFlow.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`
    )

    const topUp = topUpFlexibleCashFlowOutputs.cashWithdrawn[0]
    setTopUpDollars(
      `$${topUp.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`
    )

    setDisclaimers(newDisclaimers)
  }, [
    offerPrice,
    onboardingFee,
    closingCosts,
    mortgage,
    submarketRentDeduction,
    cashTakeout,
    daysInRenoDeduction,
    brokerFee,
    portfolioFcfPercent,

    isFinalValuation,
  ])

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box display="flex" flexDirection="column" gap="8px">
        <OutlinedBox>
          <Box display="flex" gap="8px" alignItems="flex-end" width="100%">
            <Box
              display="flex"
              alignItems="center"
              sx={{
                flex: '1 0 0',
              }}
            >
              <Typography variant="c1m" display="flex">
                Valuation{' '}
                {disclaimers.includes(VALUATION_DISCLAIMER) && (
                  <Typography variant="p6" sx={{ fontWeight: 'bold' }}>
                    {disclaimers.indexOf(VALUATION_DISCLAIMER) + 2}
                  </Typography>
                )}
              </Typography>
            </Box>
            <Box width="160px" display="flex" justifyContent="flex-end" />
            <Box width="120px" display="flex" justifyContent="flex-end">
              <Typography variant="c1m">{offerPriceDollars}</Typography>
            </Box>
          </Box>
          {mortgage ? (
            <PortfolioRow
              value1={
                <>
                  Mortgage
                  <Typography variant="p6">
                    {disclaimers.indexOf(MORTGAGE_DISCLAIMER) + 2}
                  </Typography>
                </>
              }
              value2=""
              value3={mortgageDollars}
            />
          ) : null}
          <PortfolioRow
            value1={<>Onboarding fee ({onboardingFee}%)</>}
            value2=""
            value3={onboardingFeeDollars}
          />
          {brokerFee ? (
            <PortfolioRow
              value1={<>Broker commission ({brokerFee}%)</>}
              value2=""
              value3={brokerFeeDollars}
            />
          ) : null}

          <PortfolioRow
            value1={
              <>
                Closing costs ({(closingCosts as number) * 100}%)
                <Typography variant="p6">
                  {disclaimers.indexOf(TITLE_AND_CLOSING_DISCLAIMER) + 2}
                </Typography>
              </>
            }
            value2=""
            value3={closingCostsDollars}
          />

          {submarketRentDeduction ? (
            <PortfolioRow
              value1={
                <>
                  Rent deduction
                  <Typography variant="p6">
                    {disclaimers.indexOf(RENT_REDUCTION_DISCLAIMER) + 2}
                  </Typography>
                </>
              }
              value2=""
              value3={submarketRentDeductionDollars}
            />
          ) : null}
          {daysInRenoDeduction ? (
            <PortfolioRow
              value1={
                <>
                  Days in remodel deduction
                  <Typography variant="p6">
                    {disclaimers.indexOf(DAYS_IN_REMODEL_DISCLAIMER) + 2}
                  </Typography>
                </>
              }
              value2=""
              value3={daysInRenoDeductionDollars}
            />
          ) : null}
          {cashTakeout ? (
            <PortfolioRow
              value1={
                <>
                  {cashTakeout < 0 ? 'Cash investment' : 'Cash takeout'}
                  <Typography variant="p6">
                    {disclaimers.indexOf(CASH_TAKEOUT_DISCLAIMER) + 2}
                  </Typography>
                </>
              }
              value2=""
              value3={cashTakeoutDollars}
            />
          ) : null}
        </OutlinedBox>
        <FilledBox
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: 'green1.main',
          }}
        >
          <Typography variant="c1m">Your starting account value</Typography>
          <Typography variant="c1m">{startingAccountValue}</Typography>
        </FilledBox>
        <Box display="flex" gap="4px" flexWrap="wrap">
          {disclaimers.map((disclaimer, index) => (
            <Box display="flex">
              <Typography
                key={`${disclaimer}index`}
                variant="p6"
                color="gray5.main"
                sx={{ fontSize: '6px' }}
              >
                {index + 2}
              </Typography>
              <Typography key={disclaimer} variant="p6" color="gray5.main">
                {disclaimer}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <OutlinedBox gap="8px">
        <Box display="flex" flexDirection="column" gap="4px">
          <Box display="flex" gap="8px" alignItems="flex-end" width="100%">
            <Box
              display="flex"
              alignItems="center"
              sx={{
                flex: '1 0 0',
              }}
            >
              <Typography variant="c1m" display="flex">
                Cash Flows
              </Typography>
            </Box>
            <Box width="160px" display="flex" justifyContent="flex-end">
              <Typography variant="c1m" display="flex" color="green3.main">
                (%)
              </Typography>
            </Box>
            <Box width="120px" display="flex" justifyContent="flex-end">
              <Typography variant="c1m">($)</Typography>
            </Box>
          </Box>
          <PortfolioRow
            value1="Annual flexible cash flow %"
            value2={`${(portfolioFcfPercent! - 2)?.toFixed(2)}%`}
            value3={cashFlowDollars}
          />
          <PortfolioRow
            value1="Top up %"
            value2={`${portfolioFcfPercent!.toFixed(2)}%`}
            value3={topUpDollars}
          />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="p5">Minimum hold</Typography>
          <Typography variant="p5">{minimumHoldPeriod} years</Typography>
        </Box>
      </OutlinedBox>
    </Box>
  )
}

export default PortfolioSummary
