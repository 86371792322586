import { useEffect, useMemo, useState } from 'react'
import Papa from 'papaparse'
import {
  AdminGetScopeOfWorkProjectDocument,
  AdminSyncScopeOfWorkProjectDocument,
  Core_SowProject,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useParams } from '@reach/router'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from '@flock/shared-ui'
import {
  IndividualSowProjectPageProps,
  IndividualSowProjectPagePresentationalProps,
  SowProject,
  SowItemDeficiency,
  SowCostCatalogItem,
  SowCostCatalog,
  SowAsset,
  SowProjectComputedTotals,
  convertGqlSowProjectToSowProject,
  LabelValueOption,
} from './individualSowProjectPageTypes'
import { mockMultifamilyProject, mockSfrProject } from './mockSowProjectsData'

const computeSingleProjectTotal = (
  deficiencies: SowItemDeficiency[],
  exteriorAllocation?: SowProjectComputedTotals
) => {
  let healthAndSafetyTotal = 0
  let firstTurnTotal = 0
  let zeroToThreeYearsTotal = 0
  let nonImmediatelyAddressableTotal = 0

  deficiencies.forEach((deficiency) => {
    deficiency.solutions.forEach((solution) => {
      if (solution.urgency === 'health_and_safety') {
        healthAndSafetyTotal += solution.unitPrice * solution.quantity
      } else if (solution.urgency === 'first_turn') {
        firstTurnTotal += solution.unitPrice * solution.quantity
      } else if (solution.urgency === 'zero_to_three_years') {
        zeroToThreeYearsTotal += solution.unitPrice * solution.quantity
      } else if (solution.urgency === 'non_immediately_addressable') {
        nonImmediatelyAddressableTotal +=
          solution.unitPrice * solution.quantity * solution.adjustment
      }
    })
  })

  const interiorTotal =
    healthAndSafetyTotal +
    firstTurnTotal +
    zeroToThreeYearsTotal +
    nonImmediatelyAddressableTotal

  const returnObj = {
    healthAndSafetyTotal,
    firstTurnTotal,
    zeroToThreeYearsTotal,
    nonImmediatelyAddressableTotal,

    healthAndSafetyTotalWithExteriorAllocation: healthAndSafetyTotal,
    firstTurnTotalWithExteriorAllocation: firstTurnTotal,
    zeroToThreeYearsTotalWithExteriorAllocation: zeroToThreeYearsTotal,
    nonImmediatelyAddressableTotalWithExteriorAllocation:
      nonImmediatelyAddressableTotal,

    interiorTotal: 0,
    exteriorTotal: 0,

    overallTotal: interiorTotal,
  }

  if (exteriorAllocation) {
    returnObj.healthAndSafetyTotalWithExteriorAllocation =
      healthAndSafetyTotal + exteriorAllocation.healthAndSafetyTotal
    returnObj.firstTurnTotalWithExteriorAllocation =
      firstTurnTotal + exteriorAllocation.firstTurnTotal
    returnObj.zeroToThreeYearsTotalWithExteriorAllocation =
      zeroToThreeYearsTotal + exteriorAllocation.zeroToThreeYearsTotal
    returnObj.nonImmediatelyAddressableTotalWithExteriorAllocation =
      nonImmediatelyAddressableTotal +
      exteriorAllocation.nonImmediatelyAddressableTotal

    returnObj.interiorTotal = interiorTotal

    returnObj.exteriorTotal =
      exteriorAllocation.healthAndSafetyTotal +
      exteriorAllocation.firstTurnTotal +
      exteriorAllocation.zeroToThreeYearsTotal +
      exteriorAllocation.nonImmediatelyAddressableTotal

    returnObj.overallTotal = interiorTotal + returnObj.exteriorTotal
  }

  return returnObj
}

// Function to parse CSV and return an array of items
function parseCSV(csv: string): SowCostCatalogItem[] {
  const results = Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
    complete: (parsedResult) => parsedResult.data as SowCostCatalogItem[],
  })

  // Transform the parsed data
  return results.data.map((row: any) => ({
    itemCode: row[' Item Code '].trim(),
    itemName: row[' Item Name '].trim(),
    itemNotes: row[' Item Notes ']?.trim() || '',
    uom: row[' UOM '].trim(),
    price: parseFloat(row[' Price '].replace('$', '').replace(',', '')),
    category: row[' Category ']?.trim(),
    deficiency: row[' Deficiency ']?.trim(),
    solution: row[' Solution ']?.trim(),
  }))
}

const useIndividualSowProjectPage: (
  props: IndividualSowProjectPageProps
) => IndividualSowProjectPagePresentationalProps = () => {
  const { projectUuid } = useParams()

  const [formattedAddress, setFormattedAddress] = useState('')
  const [beds, setBeds] = useState(0)
  const [baths, setBaths] = useState(0)
  const [squareFootage, setSquareFootage] = useState(0)
  const [yearBuilt, setYearBuilt] = useState(0)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const [inspectionDate, setInspectionDate] = useState('')

  const [sowProjects, setSowProjects] = useState<SowProject[]>([])
  const [selectedProject, setSelectedProject] = useState(0)

  const [projectDeficiencies, setProjectDeficiencies] = useState<
    SowItemDeficiency[]
  >([])

  const [showAddUnit, setShowAddUnit] = useState(false)

  const [expandedAll, setExpandedAll] = useState(false)

  const [projectAssets, setProjectAssets] = useState<SowAsset[]>([])

  const [showEditDeficiency, setShowEditDeficiency] = useState(false)
  const [selectedDeficiency, setSelectedDeficiency] = useState<
    SowItemDeficiency | undefined
  >()

  const [showEditAsset, setShowAsset] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState<SowAsset | undefined>()

  const [costCatalog, setCostCatalog] = useState<SowCostCatalog>({})
  const [deficiencyCatalog, setDeficiencyCatalog] = useState<SowCostCatalog>({})
  const [locationOptions, setLocationOptions] = useState<string[]>([])
  const [categoryOptions, setCategoryOptions] = useState<string[]>([])

  const deficiencyOptions = useMemo(
    () => Object.keys(deficiencyCatalog),
    [deficiencyCatalog]
  )

  const [computedTotals, setComputedTotals] = useState<
    SowProjectComputedTotals[]
  >([])

  const [exportModalOpen, setExportModalOpen] = useState(false)

  const [unitHealthAndSafetyTotal, setUnitHealthAndSafetyTotal] = useState(0)
  const [unitFirstTurnTotal, setUnitFirstTurnTotal] = useState(0)
  const [unitZeroToThreeYearsTotal, setUnitZeroToThreeYearsTotal] = useState(0)
  const [
    unitNonImmediatelyAddressableTotal,
    setUnitNonImmediatelyAddressableTotal,
  ] = useState(0)
  const [unitImmediatelyAddressableTotal, setUnitImmediatelyAddressableTotal] =
    useState(0)

  const [interiorTotal, setInteriorTotal] = useState(0)
  const [exteriorTotal, setExteriorTotal] = useState(0)
  const [overallTotal, setOverallTotal] = useState(0)
  const [deficiencySortBy, setDeficiencySortBy] = useState<string>('location')

  const [
    propertyImmediatelyAddressableTotal,
    setPropertyImmediatelyAddressableTotal,
  ] = useState(0)
  const [
    propertyNonImmediatelyAddressableTotal,
    setPropertyNonImmediatelyAddressableTotal,
  ] = useState(0)
  const [propertyHealthAndSafetyTotal, setPropertyHealthAndSafetyTotal] =
    useState(0)
  const [propertyFirstTurnTotal, setPropertyFirstTurnTotal] = useState(0)
  const [propertyZeroToThreeYearsTotal, setPropertyZeroToThreeYearsTotal] =
    useState(0)

  const [propertyTotal, setPropertyTotal] = useState(0)

  const [syncLoading, setSyncLoading] = useState(false)

  // Variables for unit creation
  const [newUnitOptions, setNewUnitOptions] = useState<LabelValueOption[]>([])
  const [costbookUuid, setCostbookUuid] = useState('')
  const [parentProjectType, setParentProjectType] = useState('')
  const [parentProjectId, setParentProjectId] = useState('')

  const { notify } = useSnackbar()

  const openEditDeficiency = (deficiencyUuid: string) => {
    if (!deficiencyUuid) {
      setShowEditDeficiency(true)
      setSelectedDeficiency(undefined)
      return
    }

    const deficiency = projectDeficiencies.find(
      (def) => def.uuid === deficiencyUuid
    )
    setSelectedDeficiency(deficiency)
    setShowEditDeficiency(true)
  }

  const onCloseEditDeficiency = () => {
    setSelectedDeficiency(undefined)
    setShowEditDeficiency(false)
  }

  const openEditAsset = (assetUuid: string) => {
    if (!assetUuid) {
      setShowAsset(true)
      setSelectedAsset(undefined)
      return
    }

    const newAsset = projectAssets.find((asset) => asset.uuid === assetUuid)
    setSelectedAsset(newAsset)
    setShowAsset(true)
  }

  const onCloseEditAsset = () => {
    setSelectedAsset(undefined)
    setShowAsset(false)
  }

  const fetchCostCatalog = async (costbookUrl: string) => {
    const response = await fetch(costbookUrl)
    const csv = await response.text()
    const items = parseCSV(csv)
    const categoryMap: Record<string, SowCostCatalogItem[]> = {}
    const deficiencyMap: Record<string, SowCostCatalogItem[]> = {}

    // Populate the category map
    items.forEach((item) => {
      if (!categoryMap[item.category]) {
        categoryMap[item.category] = []
      }
      categoryMap[item.category].push(item)
    })

    categoryMap.Other = []

    // Get sorted categories
    setCostCatalog(categoryMap)

    // Populate the deficiency map
    items.forEach((item) => {
      if (!deficiencyMap[item.deficiency]) {
        deficiencyMap[item.deficiency] = []
      }
      deficiencyMap[item.deficiency].push(item)
    })
    setDeficiencyCatalog(deficiencyMap)
  }

  const { loading, refetch } = useQuery(AdminGetScopeOfWorkProjectDocument, {
    variables: {
      input: {
        projectUuid: projectUuid || '',
      },
    },
    onCompleted: (data) => {
      if (data.getSOWProject) {
        const flattenedProjects = [
          data.getSOWProject.sowProject as Core_SowProject,
        ]
        data.getSOWProject?.sowProject?.childSowProjects?.forEach((child) => {
          flattenedProjects.push(child as Core_SowProject)
        })

        const allUnitOptions = flattenedProjects[0].address?.units?.map(
          (unit) => ({
            label: unit!.unit as string,
            value: unit!.id as string,
          })
        )

        const convertedSowProjects = flattenedProjects.map(
          convertGqlSowProjectToSowProject
        )

        const updatedNewUnitOptions =
          allUnitOptions?.filter(
            (unitOption) =>
              !flattenedProjects.some(
                (sowProject) => sowProject.addressId === unitOption.value
              )
          ) || []

        // Sort updated new unit options by label
        updatedNewUnitOptions.sort((a, b) =>
          a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
        )

        setNewUnitOptions(updatedNewUnitOptions)
        setCostbookUuid(data?.getSOWProject?.sowProject?.costbookUuid || '')
        setParentProjectType(
          data?.getSOWProject?.sowProject?.externalProjectType || ''
        )
        setParentProjectId(
          data?.getSOWProject?.sowProject?.externalProjectId || ''
        )

        setSowProjects(convertedSowProjects)

        // Get the cost catalog
        fetchCostCatalog(
          data.getSOWProject?.sowProject?.costBook?.url || '/cost-catalog.csv'
        )
      }
    },
    fetchPolicy: 'no-cache',

    // Remove when live
    skip: projectUuid === '1' || projectUuid === '2',
  })

  const [syncSowProject] = useMutation(AdminSyncScopeOfWorkProjectDocument)

  const sync = async () => {
    setSyncLoading(true)
    try {
      sowProjects.forEach(async (project) => {
        await syncSowProject({
          variables: {
            input: {
              projectUuid: project.uuid,
            },
          },
        })
      })
      await refetch()
      notify('Project synced successfully', 'success')
    } catch (e) {
      notify('Failed to sync project', 'error')
    }
    setSyncLoading(false)
  }

  useEffect(() => {
    // Handles demo projects
    // TODO: Remove when live
    if (projectUuid === '1') {
      setSowProjects(mockSfrProject)
    } else if (projectUuid === '2') {
      setSowProjects(mockMultifamilyProject)
    }
    fetchCostCatalog('/cost-catalog.csv')
  }, [])

  useEffect(() => {
    if (sowProjects.length !== 0) {
      // TODO: Set and display original beds and baths sum
      setFormattedAddress(sowProjects[0].formattedAddress)
      setYearBuilt(sowProjects[0].yearBuilt)
      setInspectionDate(
        new Date(sowProjects[0].inspectionDate).toLocaleDateString('en-US')
      )
      // Sum the beds and baths of all of the units excluding the first project
      setBeds(sowProjects[0].beds)
      setBaths(sowProjects[0].baths)
      setSquareFootage(sowProjects[0].squareFootage)

      // ===== Calculating Property Totals =====
      // Compute all the unit totals
      const sharedTotals = computeSingleProjectTotal(
        sowProjects[0].deficiencies
      )

      let newComputedTotals = [sharedTotals]
      if (sowProjects.length > 1) {
        const unitTotals = sowProjects.slice(1).map((project) => {
          const allocationRatio =
            project.squareFootage / (sowProjects[0].squareFootage || 1)

          const allocations = {
            healthAndSafetyTotal:
              sharedTotals.healthAndSafetyTotal * allocationRatio,
            firstTurnTotal: sharedTotals.firstTurnTotal * allocationRatio,
            zeroToThreeYearsTotal:
              sharedTotals.zeroToThreeYearsTotal * allocationRatio,
            nonImmediatelyAddressableTotal:
              sharedTotals.nonImmediatelyAddressableTotal * allocationRatio,

            healthAndSafetyTotalWithExteriorAllocation: 0,
            firstTurnTotalWithExteriorAllocation: 0,
            zeroToThreeYearsTotalWithExteriorAllocation: 0,
            nonImmediatelyAddressableTotalWithExteriorAllocation: 0,

            interiorTotal: 0,
            exteriorTotal: 0,
            overallTotal: 0,
          }
          return computeSingleProjectTotal(project.deficiencies, allocations)
        })
        newComputedTotals = [sharedTotals, ...unitTotals]
      }

      setComputedTotals(newComputedTotals)

      // Property level totals
      let newHealthAndSafetyTotal = 0
      let newFirstTurnTotal = 0
      let newZeroToThreeYearsTotal = 0
      let newImmediatelyAddressableTotal = 0
      let newNonImmediatelyAddressableTotal = 0
      let newPropertyTotal = 0
      if (sowProjects.length === 1) {
        newHealthAndSafetyTotal = sharedTotals.healthAndSafetyTotal
        newFirstTurnTotal = sharedTotals.firstTurnTotal
        newZeroToThreeYearsTotal = sharedTotals.zeroToThreeYearsTotal

        // If there is only one unit, the property totals are the same as the unit totals
        newImmediatelyAddressableTotal =
          sharedTotals.healthAndSafetyTotalWithExteriorAllocation +
          sharedTotals.firstTurnTotalWithExteriorAllocation +
          (1 / 3) * sharedTotals.zeroToThreeYearsTotalWithExteriorAllocation
        newNonImmediatelyAddressableTotal =
          sharedTotals.nonImmediatelyAddressableTotalWithExteriorAllocation +
          (2 / 3) * sharedTotals.zeroToThreeYearsTotalWithExteriorAllocation
        newPropertyTotal =
          newImmediatelyAddressableTotal + newNonImmediatelyAddressableTotal
      } else {
        newHealthAndSafetyTotal = newComputedTotals
          .slice(1)
          .map(
            (unitTotal) => unitTotal.healthAndSafetyTotalWithExteriorAllocation
          )
          .reduce((acc, unitTotal) => acc + unitTotal, 0)
        newFirstTurnTotal = newComputedTotals
          .slice(1)
          .map((unitTotal) => unitTotal.firstTurnTotalWithExteriorAllocation)
          .reduce((acc, unitTotal) => acc + unitTotal, 0)
        newZeroToThreeYearsTotal = newComputedTotals
          .slice(1)
          .map(
            (unitTotal) => unitTotal.zeroToThreeYearsTotalWithExteriorAllocation
          )
          .reduce((acc, unitTotal) => acc + unitTotal, 0)

        // If there are multiple units, the property totals are the sum of the unit totals
        newImmediatelyAddressableTotal = newComputedTotals
          .slice(1)
          .map(
            (unitTotal) =>
              unitTotal.healthAndSafetyTotalWithExteriorAllocation +
              unitTotal.firstTurnTotalWithExteriorAllocation +
              (1 / 3) * unitTotal.zeroToThreeYearsTotalWithExteriorAllocation
          )
          .reduce((acc, unitTotal) => acc + unitTotal, 0)
        newNonImmediatelyAddressableTotal = newComputedTotals
          .slice(1)
          .map(
            (unitTotal) =>
              unitTotal.nonImmediatelyAddressableTotalWithExteriorAllocation +
              (2 / 3) * unitTotal.zeroToThreeYearsTotalWithExteriorAllocation
          )
          .reduce((acc, unitTotal) => acc + unitTotal, 0)
        newPropertyTotal =
          newImmediatelyAddressableTotal + newNonImmediatelyAddressableTotal
      }

      setPropertyHealthAndSafetyTotal(newHealthAndSafetyTotal)
      setPropertyFirstTurnTotal(newFirstTurnTotal)
      setPropertyZeroToThreeYearsTotal(newZeroToThreeYearsTotal)
      setPropertyImmediatelyAddressableTotal(newImmediatelyAddressableTotal)
      setPropertyNonImmediatelyAddressableTotal(
        newNonImmediatelyAddressableTotal
      )

      setPropertyTotal(newPropertyTotal)

      // Set the location and category options by aggregating all of the locations and categories
      // from all existing deficiencies
      const locations = sowProjects
        .flatMap((project) => project.deficiencies)
        .map((def) => def.location)

      const categories = sowProjects
        .flatMap((project) => project.deficiencies)
        .map((def) => def.category)

      // Remove duplicates
      const uniqueLocations = Array.from(new Set(locations))
      const uniqueCategories = Array.from(new Set(categories))

      setLocationOptions(uniqueLocations)
      setCategoryOptions(uniqueCategories)
    }
  }, [sowProjects])

  const sortDeficiencies = (
    deficienciesToSort: SowItemDeficiency[],
    sortBy: string
  ) => {
    const sortedDeficiencies = [...deficienciesToSort]
    switch (sortBy) {
      case 'location':
        sortedDeficiencies.sort(
          (a, b) =>
            a.location
              .toLocaleLowerCase()
              .localeCompare(b.location.toLocaleLowerCase()) ||
            a.status
              .toLocaleLowerCase()
              .localeCompare(b.status.toLocaleLowerCase()) ||
            a.category
              .toLocaleLowerCase()
              .localeCompare(b.category.toLocaleLowerCase()) ||
            a.deficiency
              .toLocaleLowerCase()
              .localeCompare(b.deficiency.toLocaleLowerCase())
        )
        break
      case 'status':
        sortedDeficiencies.sort(
          (a, b) =>
            a.status
              .toLocaleLowerCase()
              .localeCompare(b.status.toLocaleLowerCase()) ||
            a.location
              .toLocaleLowerCase()
              .localeCompare(b.location.toLocaleLowerCase()) ||
            a.category
              .toLocaleLowerCase()
              .localeCompare(b.category.toLocaleLowerCase()) ||
            a.deficiency
              .toLocaleLowerCase()
              .localeCompare(b.deficiency.toLocaleLowerCase())
        )
        break
      case 'category':
        sortedDeficiencies.sort(
          (a, b) =>
            a.category
              .toLocaleLowerCase()
              .localeCompare(b.category.toLocaleLowerCase()) ||
            a.location
              .toLocaleLowerCase()
              .localeCompare(b.location.toLocaleLowerCase()) ||
            a.status
              .toLocaleLowerCase()
              .localeCompare(b.status.toLocaleLowerCase()) ||
            a.deficiency
              .toLocaleLowerCase()
              .localeCompare(b.deficiency.toLocaleLowerCase())
        )
        break
      case 'deficiency':
        sortedDeficiencies.sort(
          (a, b) =>
            a.deficiency
              .toLocaleLowerCase()
              .localeCompare(b.deficiency.toLocaleLowerCase()) ||
            a.location
              .toLocaleLowerCase()
              .localeCompare(b.location.toLocaleLowerCase()) ||
            a.status
              .toLocaleLowerCase()
              .localeCompare(b.status.toLocaleLowerCase()) ||
            a.category
              .toLocaleLowerCase()
              .localeCompare(b.category.toLocaleLowerCase())
        )
        break
      default:
        sortedDeficiencies.sort(
          (a, b) =>
            a.location
              .toLocaleLowerCase()
              .localeCompare(b.location.toLocaleLowerCase()) ||
            a.status
              .toLocaleLowerCase()
              .localeCompare(b.status.toLocaleLowerCase()) ||
            a.category
              .toLocaleLowerCase()
              .localeCompare(b.category.toLocaleLowerCase()) ||
            a.deficiency
              .toLocaleLowerCase()
              .localeCompare(b.deficiency.toLocaleLowerCase())
        )
        break
    }
    setProjectDeficiencies(sortedDeficiencies)
  }

  useEffect(() => {
    if (computedTotals.length === 0) {
      return
    }

    // These are single unit / level totals
    setUnitHealthAndSafetyTotal(
      computedTotals[selectedProject].healthAndSafetyTotalWithExteriorAllocation
    )
    setUnitFirstTurnTotal(
      computedTotals[selectedProject].firstTurnTotalWithExteriorAllocation
    )
    setUnitZeroToThreeYearsTotal(
      computedTotals[selectedProject]
        .zeroToThreeYearsTotalWithExteriorAllocation
    )
    setUnitNonImmediatelyAddressableTotal(
      computedTotals[selectedProject]
        .nonImmediatelyAddressableTotalWithExteriorAllocation
    )
    setUnitImmediatelyAddressableTotal(
      computedTotals[selectedProject]
        .healthAndSafetyTotalWithExteriorAllocation +
        computedTotals[selectedProject].firstTurnTotalWithExteriorAllocation +
        (1 / 3) *
          computedTotals[selectedProject]
            .zeroToThreeYearsTotalWithExteriorAllocation
    )
    setInteriorTotal(computedTotals[selectedProject].interiorTotal)
    setExteriorTotal(computedTotals[selectedProject].exteriorTotal)
    setOverallTotal(computedTotals[selectedProject].overallTotal)

    sortDeficiencies(
      sowProjects[selectedProject].deficiencies,
      deficiencySortBy
    )
    setProjectAssets(sowProjects[selectedProject].assets)
  }, [sowProjects, selectedProject, computedTotals, deficiencySortBy])

  return {
    formattedAddress,
    beds,
    baths,
    squareFootage,
    yearBuilt,
    editModalOpen,
    setEditModalOpen,

    inspectionDate,

    sowProjects,
    selectedProject,
    setSelectedProject,

    showAddUnit,
    setShowAddUnit,

    expandedAll,
    setExpandedAll,

    projectDeficiencies,
    openEditDeficiency,

    costCatalog,
    deficiencyCatalog,
    locationOptions,
    categoryOptions,
    deficiencyOptions,

    projectAssets,
    openEditAsset,

    computedTotals,
    interiorTotal,
    exteriorTotal,
    overallTotal,

    showEditDeficiency,
    selectedDeficiency,
    onCloseEditDeficiency,

    showEditAsset,
    onCloseEditAsset,
    selectedAsset,

    exportModalOpen,
    setExportModalOpen,

    unitHealthAndSafetyTotal,
    unitFirstTurnTotal,
    unitZeroToThreeYearsTotal,
    unitNonImmediatelyAddressableTotal,
    unitImmediatelyAddressableTotal,

    propertyHealthAndSafetyTotal,
    propertyFirstTurnTotal,
    propertyZeroToThreeYearsTotal,
    propertyImmediatelyAddressableTotal,
    propertyNonImmediatelyAddressableTotal,
    propertyTotal,

    loading,
    refetch,

    newUnitOptions,
    costbookUuid,
    parentProjectType,
    parentProjectId,

    sync,
    syncLoading,

    deficiencySortBy,
    setDeficiencySortBy,
  }
}

export default useIndividualSowProjectPage
