import { useMutation } from '@apollo/client'
import { AdminUpdateScopeOfWorkItemSolutionDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { FormattedTextField, useSnackbar } from '@flock/shared-ui'
import {
  Autocomplete,
  TextField,
  Box,
  Typography,
  ClickAwayListener,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SolutionRowContainer from '../../SolutionRowContainer'
import {
  adjustmentOptions,
  LabelValueOption,
  SowCostCatalog,
  SowItemSolution,
  uomOptions,
  urgencyOptions,
} from '../individualSowProjectPageTypes'
import { useSowSaveContext } from '../SowSaveContextProvider'

const autocompleteSlotProps = {
  paper: {
    sx: {
      '& .MuiAutocomplete-listbox': {
        '& .MuiAutocomplete-option': {
          fontSize: '12px',
          paddingX: '6px',
          paddingY: '3px',
        },
      },
    },
  },
}

type SolutionRowProps = SowItemSolution & {
  costCatalog: SowCostCatalog
  solutionOptions: string[]
  keywords: string[]
}

const SolutionRow = (props: SolutionRowProps) => {
  const {
    uuid,
    category,
    solution,
    description,
    unitOfMeasurement,
    unitPrice,
    quantity,
    urgency,
    adjustment,
    costCatalog,
    keywords,
    solutionOptions: deficiencySolutions,
  } = props
  const urgencyObject = urgencyOptions.find((item) => item.value === urgency)
  const adjustmentObject = adjustmentOptions.find(
    (item) => item.value === adjustment
  )

  const [curCategory, setCurCategory] = useState(category)
  const [curSolution, setCurSolution] = useState(solution)
  const [curDescription, setCurDescription] = useState(description)
  const [curUnitOfMeasurement, setCurUnitOfMeasurement] =
    useState(unitOfMeasurement)
  const [curUnitPrice, setCurUnitPrice] = useState(unitPrice || 0)
  const [curQuantity, setCurQuantity] = useState(quantity || 0)
  const [curUrgency, setCurUrgency] = useState<LabelValueOption>(
    urgencyObject || urgencyOptions[0]
  )
  const [curAdjustment, setCurAdjustment] = useState(
    adjustmentObject || adjustmentOptions[0]
  )
  const [curTotalCost, setCurTotalCost] = useState(0)
  const [curSolutionCode, setCurSolutionCode] = useState('')

  const categoryOptions = useMemo(() => Object.keys(costCatalog), [costCatalog])
  const solutionOptions = useMemo(() => {
    if (!curCategory || !costCatalog[curCategory]) return []
    return costCatalog[curCategory]
      .map((item) => item.solution)
      .sort((a, b) => {
        // Check if 'a' or 'b' contains any keyword
        const aHasKeyword = keywords.some((keyword) =>
          a.toLowerCase().includes(keyword.toLowerCase())
        )
        const bHasKeyword = keywords.some((keyword) =>
          b.toLowerCase().includes(keyword.toLowerCase())
        )

        // Sort by whether they contain keywords
        if (aHasKeyword && !bHasKeyword) return -1
        if (!aHasKeyword && bHasKeyword) return 1

        // check if 'a' or 'b' are in the deficiencySolutions
        const aInSolutions = deficiencySolutions.includes(a)
        const bInSolutions = deficiencySolutions.includes(b)

        // Sort by whether they are in the deficiencySolutions
        if (aInSolutions && !bInSolutions) return -1
        if (!aInSolutions && bInSolutions) return 1

        return 0 // No change in order if both or neither have keywords
      })
  }, [curCategory, costCatalog, deficiencySolutions, keywords])

  const [editMode, setEditMode] = useState(false)

  const { notify } = useSnackbar()
  const { onSaveSuccess, onSaveError, setSaveLoading, refetchProject } =
    useSowSaveContext()

  const onSetCurSolution = (newSolution: string) => {
    setCurSolution(newSolution)

    // Check if new solution exists in the costCatalog
    if (!curCategory || !costCatalog[curCategory]) return
    if (
      costCatalog[curCategory].find((item) => item.solution === newSolution)
    ) {
      const item = costCatalog[curCategory].find(
        (catalogRow) => catalogRow.solution === newSolution
      )
      if (!item) return

      setCurUnitPrice(item.price)
      setCurUnitOfMeasurement(item.uom)
      setCurSolutionCode(item.itemCode)
    }
  }

  const [updateSOWItemSolution] = useMutation(
    AdminUpdateScopeOfWorkItemSolutionDocument
  )

  useEffect(() => {
    const newTotalCost = curUnitPrice * curQuantity * curAdjustment.value
    setCurTotalCost(newTotalCost)
  }, [curUnitPrice, curQuantity, curAdjustment])

  const updateSolution = useCallback(
    async (
      newCategory: string,
      newSolution: string,
      newDescription: string,
      newUnitOfMeasurement: string,
      newUnitPrice: number,
      newQuantity: number,
      newUrgency: string,
      newAdjustment: number,
      newSolutionCode: string
    ) => {
      setSaveLoading(true)

      try {
        await updateSOWItemSolution({
          variables: {
            input: {
              uuid,
              category: newCategory,
              solution: newSolution,
              itemSolutionDescription: newDescription,
              unitOfMeasurement: newUnitOfMeasurement,
              unitCost: newUnitPrice,
              quantity: newQuantity,
              adjustment: newAdjustment,
              acquisitionScopeCategory: newUrgency,
              costbookItemCode: newSolutionCode,
            },
          },
        })
        await refetchProject()
        onSaveSuccess()
      } catch (e) {
        notify('Failed to update solution', 'error')
        onSaveError(`Failed to update solution: ${e.message}`)
      }
    },
    [
      notify,
      onSaveError,
      onSaveSuccess,
      setSaveLoading,
      updateSOWItemSolution,
      uuid,
    ]
  )

  const onClickAway = () => {
    setEditMode(false)
    updateSolution(
      curCategory,
      curSolution,
      curDescription,
      curUnitOfMeasurement,
      curUnitPrice,
      curQuantity,
      curUrgency.value,
      curAdjustment.value,
      curSolutionCode
    )
  }

  if (!editMode) {
    return (
      <Box
        onClick={() => {
          setEditMode(true)
        }}
        pb="8px"
      >
        <SolutionRowContainer>
          <Box>
            <Typography variant="p4">{curCategory}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curSolution}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curDescription}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curUnitOfMeasurement}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{`$${curUnitPrice.toLocaleString()}`}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curQuantity}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curUrgency.label}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curAdjustment.label}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{`$${curTotalCost.toLocaleString()}`}</Typography>
          </Box>
        </SolutionRowContainer>
      </Box>
    )
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        sx={{
          borderRadius: '4px',
          backgroundColor: 'green0.main',
        }}
        pb="8px"
      >
        <SolutionRowContainer>
          <Autocomplete
            value={curCategory}
            onChange={(_, value) => setCurCategory(value)}
            options={categoryOptions}
            disableClearable
            placeholder="Category"
            slotProps={autocompleteSlotProps}
            renderInput={(params) => (
              <TextField {...params} multiline variant="standard" size="mini" />
            )}
          />
          <Autocomplete
            value={curSolution}
            onInputChange={(_, value) => onSetCurSolution(value || '')}
            options={solutionOptions}
            disableClearable
            placeholder="Solution"
            freeSolo
            slotProps={autocompleteSlotProps}
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                variant="standard"
                size="mini"
                placeholder="Solution"
              />
            )}
          />
          <TextField
            value={curDescription}
            onChange={(e) => setCurDescription(e.target.value)}
            placeholder="Description"
            multiline
            variant="standard"
            size="mini"
          />
          <Box>
            <Autocomplete
              value={curUnitOfMeasurement}
              onChange={(_, value) => setCurUnitOfMeasurement(value || '')}
              options={uomOptions}
              disableClearable
              placeholder="UOM"
              slotProps={autocompleteSlotProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  variant="standard"
                  size="mini"
                  inputProps={{
                    ...params.inputProps,
                    style: { textAlign: 'right' },
                  }}
                />
              )}
            />
          </Box>
          <FormattedTextField
            value={curUnitPrice}
            format="dollarsAndCents"
            onChange={(e) => {
              setCurUnitPrice(e.target.value ? parseFloat(e.target.value) : 0)
            }}
            placeholder="Unit Price"
            multiline
            variant="standard"
            size="mini"
            sx={{
              '& > div': {
                paddingTop: '2px',
                paddingBottom: '0px',
              },
            }}
            align="right"
          />
          <Box width="40px">
            <FormattedTextField
              value={curQuantity}
              format="number"
              onChange={(e) => {
                setCurQuantity(e.target.value ? parseFloat(e.target.value) : 0)
              }}
              placeholder="Quantity"
              multiline
              variant="standard"
              size="mini"
              sx={{
                '& > div': {
                  paddingTop: '2px',
                  paddingBottom: '0px',
                },
              }}
              align="right"
            />
          </Box>
          <Autocomplete
            value={curUrgency}
            onChange={(_, value) => setCurUrgency(value || '')}
            options={urgencyOptions}
            disableClearable
            placeholder="Urgency"
            slotProps={autocompleteSlotProps}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                variant="standard"
                size="mini"
                inputProps={{
                  ...params.inputProps,
                  style: { textAlign: 'right' },
                }}
              />
            )}
          />
          <Autocomplete
            value={curAdjustment}
            onChange={(_, value) => {
              setCurAdjustment(value || adjustmentOptions[0])
            }}
            options={adjustmentOptions}
            disableClearable
            placeholder="100%"
            slotProps={autocompleteSlotProps}
            sx={{
              width: '100%',
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                variant="standard"
                size="mini"
                inputProps={{
                  ...params.inputProps,
                  style: { textAlign: 'right' },
                }}
              />
            )}
          />
          <TextField
            value={`$${curTotalCost.toLocaleString()}`}
            multiline
            disabled
            variant="standard"
            size="mini"
            InputProps={{
              disableUnderline: true,
              sx: {
                '& > textarea': {
                  textAlign: 'right',
                  color: 'moneyGreen.main',
                },

                '& .Mui-disabled': {
                  '-webkit-text-fill-color': 'unset',
                },
              },
            }}
          />{' '}
        </SolutionRowContainer>
      </Box>
    </ClickAwayListener>
  )
}

export default SolutionRow
